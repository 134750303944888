import { COLOR } from "../../../appStyles/colorPalette/color";

export const mainContentText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  color: COLOR.gray2,
  cursor: "pointer",
};

export const subText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  color: "#6FA9FA",
};

export const selectedRemoveText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  color: COLOR.red,
};
