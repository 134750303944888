import React from "react";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { COLOR } from "../../../appStyles/colorPalette/color";

const BpIcon = styled("span")(({ theme, isDisable }) => ({
  borderRadius: "4px",
  width: "20px",
  height: "20px",
  border: `1px solid ${isDisable ? COLOR.gray2 : "#6FA9FA"}`,
  backgroundColor: isDisable ? COLOR.gray2 : COLOR.white,
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: COLOR.blue,
  "&:before": {
    display: "block",
    height: "20px",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
        padding: 0,
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon isDisable={props.disabled} />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const CustomizedCheckbox = ({ checked, onChange = () => {}, ...props }) => {
  return <BpCheckbox checked={!!checked} onChange={onChange} {...props} />;
};

export default CustomizedCheckbox;
