import { COLOR } from "../../appStyles/colorPalette/color";

export const hideApprovedText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  color: COLOR.gray2,
};

export const approvedAllText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  color: COLOR.gray2,
  p: 2,
};

export const PaginationStyle = {
  ".MuiPaginationItem-root.Mui-selected ": {
    backgroundColor: COLOR.bottonActive,
    color: "#fff",
    fontWeight: 600,
  },
  ".MuiPaginationItem-root": {
    backgroundColor: "#3C3E5C",
    color: "#fff",
    fontFamily: "IBMPlexSans-Light",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "17px",
  },
  "div.MuiPaginationItem-root": {
    minHeight: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const PaginationContainerGridStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: "0px 16px",
  marginTop: "70px",
  marginBottom: "90px",
};

export const bodyImageContainerStyle = {
  width: "100%",
  p: 2,
  alignItems: "center",
  height: "80%",
};

export const traitsDropdownStyle = {
  color: "#a0a2b1",
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
};

export const dropdownSelectStyle = {
  height: "40px",
  background: "#3C3E5C",
  borderRadius: "8px",
  paddingLeft: "15px",
  display: "flex",
  alignItems: "center",
};

export const removedStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
  mt: { xs: "5px", sm: "5px", md: 0, lg: 0 },
  pl: "10px",
  pr: "10px",
};

export const checkBoxStyle = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "column",
    md: "column",
    lg: "row",
    xl: "row",
  },
  alignItems: {
    xs: "flex-start",
    sm: "flex-start",
    md: "flex-start",
    lg: "flex-start",
  },
};

export const formTraitStyle = {
  width: "200px",
  backgroundColor: "#3C3E5C",
  borderRadius: "8px",
  mb: { xs: "7px", sm: "7px", md: "7px", lg: 0 },
};

export const formSortByStyle = {
  ...formTraitStyle,
  marginRight: 1,
};

export const midHeaderBox = {
  minWidth: 120,
  margin: "15px",
  display: "flex",
  alignItems: {
    xs: "flex-start",
    sm: "flex-start",
    md: "flex-start",
    lg: "flex-start",
    xl: "center",
  },
  justifyContent: "space-between",
  flexDirection: {
    xs: "column",
    sm: "column",
    md: "row",
    lg: "row",
  },
};

export const mainContainer = {
  display: "flex",
  background: "#1D2145",
  minHeight: "100vh",
  flexDirection: "column",
};

export const subMainContainer = {
  height: "fill-available",
  p: 1,
  borderRadius: 10,
  overflow: "hidden",
};

export const gridContainer = {
  background: COLOR.background,
  width: "100%",
  flexDirection: "column",
  height: "100%",
};

export const gridSubContainer = {
  width: "100%",
  p: 2,
  alignItems: "center",
};

export const bodySubHeader = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
};

export const approvedAllBox = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
  pr: 2,
};

export const approvedAllButtonStyle = {
  mt: 3,
  mb: 2,
  backgroundColor: "#6fa9fa",
  borderRadius: "8px",
  color: "#ffffff",
  cursor: "pointer",
  textTransform: "none",
  "&.Mui-disabled": {
    backgroundColor: "#6fa9fa",
    color: "#ffffff",
    opacity: "0.5",
  },
};

export const chipWrapper = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
  pr: 2,
  pl: 2,
};

export const itemPerPageStyle = {
  fontFamily: "IBMPlexSans-Light",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "21px",
  color: "#A0A2B1",
};

export const itemSelect = {
  width: "75px",
  height: "31px",
  color: "#A0A2B1",
  background: "#3C3E5C",
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  borderRadius: "8px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    color: "#A0A2B1",
  },
  "& .MuiInputBase-input": {
    display: "flex",
    justifyContent: "center",
  },
};
