export const COLOR = {
  backgroundColor: "#1D2145",
  bottonActive: "#6FA9FA",
  white: "#FFFFFF",
  blue: "#6FA9FA",
  black: "#000000",
  black2: "#3C3E5C",
  gray2: "#A0A2B1",
  background: "#292C4E",
  blue2: "#1d2145",
  rightDrawerBG: "#3F4265",
  red: "#ff0000",
};
