import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { differenceInDays, formatDistance, format } from "date-fns";
import {
  approvedStyle,
  approvedSwitchStyle,
  commentContainerStyle,
  commentInputBox,
  commentInputCont,
  commentStyle,
  commentTextStyle,
  drawerStyle,
  headerName,
  hrStyle,
  hrStyleBody,
  idStyle,
  imageConfigured,
  imageContainer,
  imageContainer1,
  selectText,
  subContainerStyle,
} from "./style";
import ClearIcon from "@mui/icons-material/Clear";
import CustomAvatar from "../../common/customAvatar";
import CustomizedCheckbox from "../../common/customizedCheckbox";
import CustomGenDetailsViewer from "../../common/customGenDetailsViewer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import { getCommentsById, sendCommentsById } from "../../../services";
import { SendIcon } from "../../../assets/icon";
import { useAuth } from "../../../hooks/auth";

const CommentDrawer = ({
  open,
  close,
  profileData,
  checkData,
  checkHandle,
  onClickProperties,
  collection,
  traits = [],
}) => {
  let {
    signedImageUrl,
    id,
    isDeleted,
    itemName,
    isApproved,
    commentsCount,
    signedThumbnailUrl,
    isRemoved,
  } = profileData;
  const [filter, setFilter] = useState("latest");
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [sendLoading, setSendLoading] = useState(false);
  const [showSendButton, setshowSendButton] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (open)
      if (Number(commentsCount || 0) && filter) {
        handleLoadComments();
      } else {
        setComments([]);
      }
  }, [open, filter]);

  const handleLoadComments = async () => {
    try {
      let apiResponse = await getCommentsById({
        itemId: id,
        orderBy: filter,
        collection,
      });
      if (apiResponse?.data?.length) {
        setComments(apiResponse.data);
      }
    } catch (ex) {
      console.log("Error while fetching comments for ", id, ex);
    }
  };

  const handleSendComment = async () => {
    try {
      setSendLoading(true);
      let commentResponse = await sendCommentsById({
        itemId: id,
        comment,
        collection,
      });
      if (commentResponse?.data) {
        handleLoadComments();
        setComment("");
        setSendLoading(false);
      }
    } catch (ex) {
      setSendLoading(false);
      console.log("Error while adding comments for ", id, ex);
    }
  };

  const getDateTimeString = (date) => {
    if (date) {
      if (differenceInDays(new Date(), new Date(date)) < 8) {
        if (differenceInDays(new Date(), new Date(date)) > 4)
          return "One week ago";
        else
          return formatDistance(new Date(date), new Date(), {
            addSuffix: true,
          });
      } else {
        return format(new Date(date), "MMM dd, yyyy");
      }
    } else return "";
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={close}
      PaperProps={{ ...drawerStyle }}
    >
      <Box sx={{ ...commentContainerStyle }}>
        <Typography sx={{ ...commentStyle }}>{`Comments`}</Typography>
        <IconButton onClick={close}>
          <ClearIcon style={{ color: "white" }} />
        </IconButton>
      </Box>
      <Box sx={{ ...imageContainer }}>
        <Box sx={{ width: "45%" }}>
          <Box
            component={"img"}
            src={signedImageUrl ?? signedThumbnailUrl}
            sx={{ ...imageConfigured }}
          />
        </Box>
        <Box sx={{ width: "100%", ml: "25px" }}>
          <Box sx={{ ...subContainerStyle }}>
            <Typography sx={{ ...idStyle }}>#{id}</Typography>
            <Box sx={{ ...approvedSwitchStyle }}>
              <Typography sx={{ ...approvedStyle }}>
                {isApproved === 1 ? `Approved` : `Approve`}
              </Typography>
              <CustomizedCheckbox
                disabled={isRemoved === 1 ? true : false}
                checked={isApproved === 1 ? true : false}
                onChange={checkHandle}
              />
            </Box>
          </Box>
          <div className="box-details">
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "8px",
                width: "100%",
              }}
            >
              {traits.map((ele) => (
                <CustomGenDetailsViewer
                  key={ele}
                  label={ele}
                  value={
                    profileData[ele] ? profileData[ele][ele] || "None" : "None"
                  }
                  onclickFilter={() => {}}
                  content={
                    profileData[ele] ? profileData[ele]["percentage"] || 0 : 0
                  }
                />
              ))}
            </Box>
          </div>
        </Box>
      </Box>
      <Box sx={{ ...hrStyle }} />
      <Box sx={{ ...hrStyleBody }} />
      <Box sx={{ ...commentInputCont }}>
        <Box>
          <CustomAvatar
            name={user?.name ? user?.name[0] : ""}
            customStyle={{
              fontFamily: "IBM Plex Sans",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              color: "black",
              width: "38px",
              height: "38px",
              background: user?.backGroundColor,
            }}
          />
        </Box>
        <Box
          sx={{
            border: "1px solid #626586",
            width: "fill-available",
            minHeight: "38px",
            display: "flex",
            alignItems: "center",
            borderRadius: "4px",
          }}
        >
          <TextField
            fullWidth
            multiline
            maxRows={4}
            placeholder="Add your comment here..."
            variant="standard"
            disabled={sendLoading}
            onFocus={() => setshowSendButton(!showSendButton)}
            onBlur={() => setshowSendButton(!showSendButton)}
            value={comment || ""}
            onChange={(e) => setComment(e.target.value)}
            InputProps={{
              disableUnderline: true,
              style: {
                fontFamily: "IBMPlexSans-Light",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                color: "#ABAEC2",
              },
            }}
            sx={{ ...commentInputBox }}
          />
          {comment || showSendButton ? (
            <Box
              sx={{
                width: "34px",
                height: "34px",
                background: "#6FA9FA",
                borderRadius: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: sendLoading ? "default" : "pointer",
              }}
              onClick={() => !sendLoading && handleSendComment()}
            >
              <SendIcon />
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
      {Boolean(comments.length) && (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Select
                disableUnderline
                variant={"standard"}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                IconComponent={(props) => (
                  <ExpandMoreIcon {...props} style={{ color: "white" }} />
                )}
                sx={{ ...selectText }}
              >
                <MenuItem value={"latest"}>Newest First</MenuItem>
                <MenuItem value={"oldest"}>Oldest First</MenuItem>
              </Select>
            </Box>
            <Typography sx={{ ...commentTextStyle }}>
              {comments.length > 1
                ? `${comments.length} Comments`
                : `${comments.length} Comment`}
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          background: "rgba(217, 217, 217, 0.28)",
          height: "1px",
          width: "100%",
          mt: "10px",
          mb: "10px",
        }}
      />
      <Box sx={{ width: "100%", overflowY: "auto" }}>
        {Boolean(comments.length) &&
          comments.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  ...imageContainer1,
                  borderBottom:
                    comments.length - 1 === index
                      ? "0px"
                      : `1px solid rgba(217, 217, 217, 0.28);`,
                }}
              >
                <CustomAvatar
                  name={
                    item?.name
                      ? item?.name.split(" ")[0][0] +
                        (item?.name.split(" ")[1]
                          ? item?.name.split(" ")[1][0]
                          : "")
                      : ""
                  }
                  customStyle={{
                    fontFamily: "IBM Plex Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#000000",
                    background: item.backGroundColor,
                  }}
                />
                <Box sx={{ ml: "10px", width: "calc(100% - 60px)" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      ml: "10px",
                    }}
                  >
                    <Typography sx={{ ...headerName }}>{item.name}</Typography>
                    <Typography sx={{ ...commentTextStyle, fontSize: "12px" }}>
                      {item.createdOn ? getDateTimeString(item.createdOn) : ""}
                    </Typography>
                  </Box>
                  <Typography sx={{ ...commentTextStyle, ml: "10px" }}>
                    {item.comment}
                  </Typography>
                </Box>
              </Box>
            );
          })}
      </Box>
      {/* <Box component={"img"} src={signedImageUrl} sx={{ ...imageConfigured }} /> */}
    </Drawer>
  );
};

export default CommentDrawer;
