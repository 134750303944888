import { createBrowserRouter } from "react-router-dom";

import { AuthLayout } from "../hooks/auth";
import Login from "../pages/Login";
import Layout from "../components/Layout";
import GenerativeViewer from "../pages/GenerativeViewer";

const routes = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        element: <Layout />,
        children: [
          {
            path: "/",
            element: <GenerativeViewer />,
          },
        ],
      },
    ],
  },
]);

export default routes;
