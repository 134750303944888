import { COLOR } from "../../appStyles/colorPalette/color";

export const approvedText = {
  fontFamily: "IBMPlexSans-Medium",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "17px",
  color: COLOR.white,
  pr: "8px",
};

export const removeText = {
  fontFamily: "IBMPlexSans-Medium",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "17px",
  color: COLOR.white,
  pr: "8px",
  pl: {
    xs: "8px",
    sm: "10px",
    md: "12px",
    lg: "14px",
    xl: "16px",
  },
};

export const commentText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "17px",
  color: COLOR.gray2,
};

export const idText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  lineHeight: "30px",
  color: "#6FA9FA",
};

export const countText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "21px",
  color: "#A0A2B1",
  cursor: "pointer",
};

export const subContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mb: "10px",
};
