export const mainBody = {
  background: `rgba(60, 62, 92, 0.52)`,
  border: `1px solid rgba(255, 255, 255, 0.4)`,
  boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
  borderRadius: "8px",
  mr: "10px",
  mb: "10px",
  mt: "10px",

  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "29px",
  color: " rgba(255, 255, 255, 0.8)",
};

export const mainBody2 = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "29px",
  color: "#fff",
};
