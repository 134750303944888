import React from "react";
import Avatar from "@mui/material/Avatar";

const CustomAvatar = ({ name, customStyle = {} }) => {
  return (
    <Avatar
      sx={{
        backgroundColor: "#DCE8F9",
        fontFamily: "Inter-Medium",
        fontStyle: "normal",
        fontWeight: "400",
        color: "black",
        textTransform: "capitalize",
        ...customStyle,
      }}
    >
      {name}
    </Avatar>
  );
};

export default CustomAvatar;
