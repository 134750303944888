import React from "react";
import { Box, Typography, Divider, Button } from "@mui/material";
import { COLOR } from "../../../appStyles/colorPalette/color";
import Grid from "@mui/material/Grid";
import DoneIcon from "@mui/icons-material/Done";
import {
  headerText,
  subActiveHeaderText,
  subHeaderContainer,
  subHeaderText,
} from "./style";

const SelectTraits = ({ selected, onSelected, traitsData = [] }) => {
  return (
    <Box
      sx={{
        width: "95vw",
        height: "627px",
        background: COLOR.black2,
        borderRadius: "5px",
        padding: "10px 12px 15px",
        mt: -1,
        mb: -1,
        flex: 1,
        overflowX: "hidden",
      }}
    >
      {traitsData.map((item, index) => {
        return (
          <Box key={index} sx={{ background: COLOR.black2 }}>
            <Typography sx={{ ...headerText }}>{item?.property}</Typography>
            <Divider
              sx={{
                border: "1px solid rgba(160, 162, 177, 0.38)",
                marginTop: "10px",
                marginBottom: "20px",
              }}
            />
            <Box sx={{ ...subHeaderContainer }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ pt: "10px" }}
              >
                {item?.attribute?.map((subitem, index) => (
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={3}
                    key={index}
                    onClick={() => {
                      onSelected(item.property, subitem);
                    }}
                  >
                    {Boolean(selected.length) &&
                    selected.findIndex((ele) =>
                      ele.value.includes(subitem.attributename) && ele.name == item?.property
                    ) !== -1 ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={subActiveHeaderText}>
                          {subitem.attributename}
                        </Typography>
                        <DoneIcon style={{ color: COLOR.white }} />
                      </Box>
                    ) : (
                      <Typography sx={subHeaderText}>
                        {subitem.attributename}
                      </Typography>
                    )}
                    <Divider
                      sx={{
                        border:
                          Boolean(selected.length) &&
                          selected.findIndex((ele) =>
                            ele.value.includes(subitem.attributename) && ele.name == item?.property
                          ) !== -1
                            ? " 1px solid #FFFFFF;"
                            : "1px solid rgba(98, 100, 124, 0.3)",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SelectTraits;
