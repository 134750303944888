import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Button, CircularProgress, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { COLOR } from "../../appStyles/colorPalette/color";
import {
  generativeViewer,
  getCollections,
  getTraitsByCollection,
  onHandleApproved,
} from "../../services";
import CustomBreadcrumbs from "../../components/common/customBreadcrumbs";
import {
  hideApprovedText,
  PaginationStyle,
  PaginationContainerGridStyle,
  bodyImageContainerStyle,
  dropdownSelectStyle,
  removedStyle,
  checkBoxStyle,
  formTraitStyle,
  formSortByStyle,
  midHeaderBox,
  mainContainer,
  subMainContainer,
  gridContainer,
  gridSubContainer,
  bodySubHeader,
  approvedAllBox,
  approvedAllButtonStyle,
  chipWrapper,
  itemPerPageStyle,
  itemSelect,
} from "./style";
import CustomSwitch from "../../components/common/customSwitch";
import SelectTraits from "../../components/GenerativeViewer/SelectTraits";
import CustomChip from "../../components/common/customChip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageTab from "../../components/GenerativeViewer/ImageTab";
import DrawerHeader from "../../components/common/DrawerHeader";

const pageLimitOptions = [10, 50, 100];

export default function GenerativeViewer() {
  const [collections, setCollections] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [filterTraits, setFilterTraits] = useState([]);
  const [traitsAtt, setTraitsAtt] = useState({});
  const [filterParam, setFilterParam] = useState({});

  const [approvedChecked, setApprovedChecked] = useState(false);
  const [limit, setLimit] = useState(10);
  const [paginationCount, setPaginationCount] = useState(null);
  const [offset, setOffset] = useState(1);
  const [hideContent, setHideContent] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [showRemoved, setShowRemoved] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectRemoved, setSelectRemoved] = useState("");
  const [role, setRole] = useState(false);

  const changedFilters = useRef(false);

  const handleGetCollections = async () => {
    try {
      setLoading(true);
      let resp = await getCollections();
      if (resp && Array.isArray(resp?.data)) {
        setCollections(resp.data);
      } else {
        setLoading(false);
      }
    } catch (ex) {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCollections();
    return () => {
      setRole(false);
    };
  }, []);

  const handleFetchNfts = async (params = {}, loadingState = false) => {
    let filter = {
      limit,
      offset: limit * (offset - 1),
      ...(Object.keys(params).includes("hideApproved") && {
        hideApproved: params.hideApproved,
      }),
      filter: { ...filterParam },
      ...params,
    };
    if (filter.filter.traits && !Object.keys(filter.filter.traits).length)
      delete filter.filter.traits;
    let apiResponse = await generativeViewer(filter);
    if (apiResponse && apiResponse.data) {
      let items = apiResponse.data.items || [];
      setSelectRemoved(apiResponse.data.removedCount);
      setProfileData(items);
      setPaginationCount(apiResponse.data.count);
      if (
        items.filter((ele) => ele.isApproved === 1 || ele.isRemoved === 1)
          .length === items.length
      ) {
        setApprovedChecked(true);
      } else {
        setApprovedChecked(false);
      }
    }
    loadingState && setLoading(false);
  };

  const onHandleSelect = (key, value) => {
    changedFilters.current = true;
    let valueOf = {
      name: key,
      value: [value.attributename],
    };
    if (filterTraits.some((el) => el.name === key)) {
      let k1 = filterTraits.filter((ele) => ele.name === key);
      if (k1[0].value.includes(value.attributename)) {
        let arrk = k1[0].value.filter((item) => {
          return item !== value.attributename;
        });
        if (Boolean(arrk.length)) {
          let addInsideDelete = {
            name: key,
            value: [...arrk],
          };
          let k12 = filterTraits.filter((ele) => ele.name !== key);
          setFilterTraits([...k12, addInsideDelete]);
        } else {
          let k12 = filterTraits.filter((ele) => ele.name !== key);
          setFilterTraits([...k12]);
        }
      } else {
        let addInside = {
          name: key,
          value: [...k1[0].value, value.attributename],
        };
        let k12 = filterTraits.filter((ele) => ele.name !== key);
        setFilterTraits([...k12, addInside]);
      }
    } else {
      setFilterTraits([...filterTraits, valueOf]);
    }
  };

  const handlePageChange = (value) => {
    let filter = {
      offset: limit * (value - 1),
      ...(hideContent && { hideApproved: false }),
      ...(showApproved && { hideApproved: true }),
      ...(showRemoved && { showRemoved: true }),
    };
    handleFetchNfts(filter);
  };

  const handleChangeLimit = ({ target }) => {
    const { value } = target;
    let filter = {
      limit: value,
      offset: 0,
      ...(hideContent && { hideApproved: false }),
      ...(showApproved && { hideApproved: true }),
      ...(showRemoved && { showRemoved: true }),
    };
    setOffset(1);
    setLimit(value);
    handleFetchNfts(filter);
  };

  useEffect(() => {
    if (collections.length) {
      let collection = collections[0].value;
      handleGetTraitsByCollection(collection);
      setFilterParam({ collection, sortBy: "asc" });
      onHandleUserRole(collection);
      handleFetchNfts(
        {
          filter: {
            collection,
            sortBy: "asc",
          },
        },
        true
      );
    }
  }, [collections]);

  const onHandleRemoveFromChip = (key, value) => {
    let k1 = filterTraits.filter((ele) => ele.name === key.name);
    let arrk = k1[0].value.filter((item) => item !== value);

    if (Boolean(arrk.length)) {
      let addInsideDelete = {
        name: key.name,
        value: [...arrk],
      };
      let k12 = filterTraits.filter((ele) => ele.name !== key.name);
      setFilterTraits([...k12, addInsideDelete]);
      handleChangeClearTraits([...k12, addInsideDelete]);
    } else {
      let k12 = filterTraits.filter((ele) => ele.name !== key.name);
      setFilterTraits([...k12]);
      handleChangeClearTraits([...k12]);
    }
  };

  const handleChangeClearTraits = (modifyParam) => {
    let traits = {};
    modifyParam &&
      modifyParam.map((ele) => {
        let attributes = traitsAtt[filterParam.collection].find(
          (e) => e.property === ele.name
        ).attribute;
        traits = {
          ...traits,
          [ele.name]: ele.value.map(
            (att) => attributes.find((el) => el.attributename === att).id
          ),
        };
      });
    setFilterParam({ ...filterParam, traits: traits });
    handleFetchNfts({
      ...(hideContent && { hideApproved: false }),
      ...(showApproved && { hideApproved: true }),
      ...(showRemoved && { showRemoved: true }),
      filter: { ...filterParam, traits: traits },
    });
  };

  const onHandleClearAll = async () => {
    setFilterTraits([]);
    let filter = { ...filterParam, traits: {} };
    setFilterParam(filter);
    await handleFetchNfts({
      ...(hideContent && { hideApproved: false }),
      ...(showApproved && { hideApproved: true }),
      ...(showRemoved && { showRemoved: true }),
      filter,
    });
  };

  const onHandleApplyFilter = async (header, value) => {
    let valueOf = {
      name: header,
      value: [value],
    };
    let attributeId = traitsAtt[filterParam.collection]
      .find((e) => e.property === header)
      .attribute.find((el) => el.attributename === value)?.id;
    if (attributeId) {
      setFilterTraits([valueOf]);
      setOffset(1);
      let filter = {
        offset: 0,
        ...(hideContent && { hideApproved: false }),
        ...(showApproved && { hideApproved: true }),
        ...(showRemoved && { showRemoved: true }),
        filter: {
          ...filterParam,
          traits: {
            [header]: [attributeId],
          },
        },
      };
      setFilterParam({
        ...filterParam,
        traits: {
          [header]: [attributeId],
        },
      });
      handleFetchNfts(filter);
    }
  };

  const handleGetTraitsByCollection = async (collectionName) => {
    try {
      let resp = await getTraitsByCollection({ collectionName });
      setTraitsAtt((prevState) => ({
        ...prevState,
        [collectionName]: resp.data,
      }));
    } catch (ex) {}
  };

  const onHandleUserRole = (user) => {
    setRole(user === "alexandra_nechita" ? true : false);
  };

  const handleChange = (event, path) => {
    const {
      target: { value },
    } = event;
    let filter = { ...filterParam, [path]: value };
    if (path === "collection") {
      setLoading(true);
      !traitsAtt[value]?.length && handleGetTraitsByCollection(value);
      filter = {
        collection: value,
        sortBy: "asc",
        traits: {},
      };
      setFilterTraits([]);
      onHandleUserRole(value);
      setHideContent(false);
      setShowApproved(false);
      setShowRemoved(false);
    }
    setOffset(1);
    setFilterParam(filter);
    handleFetchNfts(
      {
        offset: 0,
        ...(hideContent && path === "sortBy" && { hideApproved: false }),
        ...(showApproved && path === "sortBy" && { hideApproved: true }),
        ...(showRemoved && path === "sortBy" && { showRemoved: true }),
        filter,
      },
      path === "collection"
    );
  };

  const handleChangeTraits = () => {
    if (changedFilters.current) {
      let traits = {};
      filterTraits &&
        filterTraits.map((ele) => {
          let attributes = traitsAtt[filterParam.collection].find(
            (e) => e.property === ele.name
          ).attribute;
          traits = {
            ...traits,
            [ele.name]: ele.value.map(
              (att) => attributes.find((el) => el.attributename === att).id
            ),
          };
        });
      changedFilters.current = false;
      setFilterParam({ ...filterParam, traits: traits });
      handleFetchNfts({
        offset: 0,
        ...(hideContent && { hideApproved: false }),
        ...(showApproved && { hideApproved: true }),
        ...(showRemoved && { showRemoved: true }),
        filter: { ...filterParam, traits: traits },
      });
    }
  };

  const handleChangePage = async (event, value) => {
    setOffset(value);
    handlePageChange(value);
  };

  const onHandleChangeHideContent = async (event) => {
    let param = {
      offset: 0,
      ...(event.target.checked && { hideApproved: false }),
    };
    handleFetchNfts(param);
    setOffset(1);
    setHideContent(event.target.checked);
    setShowApproved(false);
    setShowRemoved(false);
  };

  const onHandleShowApprovedContent = async (event) => {
    let param = {
      offset: 0,
      ...(event.target.checked && { hideApproved: true }),
    };
    handleFetchNfts(param);
    setOffset(1);
    setShowApproved(event.target.checked);
    setHideContent(false);
    setShowRemoved(false);
  };

  const onHandleShowRemovedContent = async (event) => {
    let param = {
      offset: 0,
      ...(event.target.checked && { showRemoved: true }),
    };
    setOffset(1);
    handleFetchNfts(param);
    setShowRemoved(event.target.checked);
    setHideContent(false);
    setShowApproved(false);
  };

  const onHandleChangeApproveAll = async () => {
    setApprovedChecked(true);
    let allItem = [];
    profileData.forEach(
      (ele) => !ele.isApproved && !ele.isRemoved && allItem.push(ele.id)
    );
    let apiResponse = await onHandleApproved({
      ApprovedItemIds: allItem,
      collection: filterParam.collection,
    });
    if (apiResponse.statusCode === 200) {
      if (hideContent) {
        let param = {
          offset: limit * (offset - 1),
          ...(hideContent && { hideApproved: false }),
          ...(showApproved && { hideApproved: true }),
          ...(showRemoved && { showRemoved: true }),
        };
        handleFetchNfts(param);
      } else {
        let list = [];
        profileData.forEach((ele) => {
          if (!ele.isApproved && !ele.isRemoved) {
            ele.isApproved = 1;
          }
          list.push(ele);
        });
        setProfileData([...list]);
      }
    }
  };

  const onHandleChangeApproved = async (id_Param) => {
    let apiResponse = await onHandleApproved({
      collection: filterParam.collection,
      ...id_Param,
    });
    if (apiResponse.statusCode === 200) {
      if (hideContent || showApproved) {
        let param = {
          offset: limit * (offset - 1),
          ...(hideContent && { hideApproved: false }),
          ...(showApproved && { hideApproved: true }),
          ...(showRemoved && { showRemoved: true }),
        };
        handleFetchNfts(param);
      } else {
        const NFT_List_temp = [];
        profileData.forEach((ele) => {
          if (ele.id === Object.values(id_Param)[0][0]) {
            ele.isApproved = Number(!ele.isApproved);
          }
          NFT_List_temp.push(ele);
        });
        let value =
          NFT_List_temp.filter(
            (ele) => ele.isApproved === 1 || ele.isRemoved === 1
          ).length === NFT_List_temp.length;
        setApprovedChecked(value);
        setProfileData(NFT_List_temp);
      }
    }
  };

  const onHandleChangeRemoved = async (id_Param) => {
    let apiResponse = await onHandleApproved({
      collection: filterParam.collection,
      ...id_Param,
    });
    if (apiResponse.statusCode === 200) {
      if (showRemoved) {
        let param = {
          offset: limit * (offset - 1),
          ...(hideContent && { hideApproved: false }),
          ...(showApproved && { hideApproved: true }),
          ...(showRemoved && { showRemoved: true }),
        };
        handleFetchNfts(param);
      } else {
        const NFT_List_temp = [];
        profileData.forEach((ele) => {
          if (ele.id === Object.values(id_Param)[0][0]) {
            ele.isRemoved = Number(!ele.isRemoved);
          }
          NFT_List_temp.push(ele);
        });
        setSelectRemoved(
          Object.keys(id_Param)[0] === "includedItemIds"
            ? selectRemoved - 1
            : selectRemoved + 1
        );
        let value =
          NFT_List_temp.filter(
            (ele) => ele.isApproved === 1 || ele.isRemoved === 1
          ).length === NFT_List_temp.length;
        setApprovedChecked(value);
        setProfileData(NFT_List_temp);
      }
    }
  };

  return (
    <Box sx={mainContainer}>
      <DrawerHeader />
      {collections.length && !isLoading ? (
        <Grid container sx={subMainContainer}>
          <Grid container sx={gridContainer}>
            <Grid item sx={gridSubContainer}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                color={"#FFF"}
                fontFamily={"IBMPlexSans-Medium"}
              >
                Generative Viewer
              </Typography>
            </Grid>
            <Divider sx={{ border: "1px solid rgba(160, 162, 177, 0.22)" }} />

            <Box sx={midHeaderBox}>
              <Box sx={{}}>
                <FormControl size={"small"} sx={formSortByStyle}>
                  <Select
                    disableUnderline
                    variant={"standard"}
                    onChange={(e) => handleChange(e, "collection")}
                    label="Collection"
                    value={filterParam["collection"] || ""}
                    sx={{ ...hideApprovedText, ...dropdownSelectStyle }}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} style={{ color: "#A0A2B1" }} />
                    )}
                  >
                    {collections.map((ele) => (
                      <MenuItem value={ele.value} key={ele.value}>
                        {ele.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size={"small"} sx={formSortByStyle}>
                  <Select
                    defaultValue="default"
                    disableUnderline
                    variant={"standard"}
                    value={filterParam["sortBy"] || "asc"}
                    onChange={(e) => handleChange(e, "sortBy")}
                    label="Sort by"
                    sx={{ ...hideApprovedText, ...dropdownSelectStyle }}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} style={{ color: "#A0A2B1" }} />
                    )}
                  >
                    <MenuItem value="default" sx={{ display: "none" }}>
                      Sort by
                    </MenuItem>
                    <MenuItem value={`asc`}>A-Z</MenuItem>
                    <MenuItem value={`desc`}>Z-A</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size={"small"} sx={formTraitStyle}>
                  <Select
                    disableUnderline
                    variant={"standard"}
                    onClose={handleChangeTraits}
                    label="Select Traits"
                    sx={{ ...hideApprovedText, ...dropdownSelectStyle }}
                    IconComponent={(props) => (
                      <ExpandMoreIcon {...props} style={{ color: "#A0A2B1" }} />
                    )}
                    defaultValue="default"
                  >
                    <MenuItem value="default" sx={{ display: "none" }}>
                      Select Traits
                    </MenuItem>
                    <SelectTraits
                      selected={filterTraits}
                      onSelected={onHandleSelect}
                      traitsData={traitsAtt[filterParam.collection]}
                    />
                  </Select>
                </FormControl>
              </Box>
              <Box sx={checkBoxStyle}>
                <Box sx={removedStyle}>
                  <CustomSwitch
                    isSwitch={hideContent}
                    onChangeFunction={onHandleChangeHideContent}
                  />
                  <Typography
                    sx={{ ...hideApprovedText, width: "max-content" }}
                  >{`Hide Approved`}</Typography>
                </Box>
                <Box sx={removedStyle}>
                  <CustomSwitch
                    isSwitch={showApproved}
                    onChangeFunction={onHandleShowApprovedContent}
                  />
                  <Typography
                    sx={{ ...hideApprovedText, width: "max-content" }}
                  >{`Show only Approved`}</Typography>
                </Box>
                {role && (
                  <Box sx={removedStyle}>
                    <CustomSwitch
                      isSwitch={showRemoved}
                      onChangeFunction={onHandleShowRemovedContent}
                    />
                    <Typography
                      sx={{ ...hideApprovedText, width: "max-content" }}
                    >{`Show only Removed`}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={bodySubHeader}>
              <CustomBreadcrumbs
                breadProps={filterTraits}
                rootLink={onHandleClearAll}
                collection={
                  collections.find(
                    (ele) => ele.value === filterParam.collection
                  )?.name
                }
                removedItem={selectRemoved}
                role={role}
              />
              <Box sx={approvedAllBox}>
                <Button
                  disabled={approvedChecked}
                  variant="contained"
                  onClick={onHandleChangeApproveAll}
                  sx={approvedAllButtonStyle}
                >
                  Approve all on page
                </Button>
              </Box>
            </Box>
            <Box sx={chipWrapper}>
              <CustomChip
                chipData={filterTraits}
                handleDelete={onHandleRemoveFromChip}
                onHandleClear={onHandleClearAll}
              />
            </Box>
            <Grid item sx={{ ...bodyImageContainerStyle }}>
              {
                <Grid
                  container
                  spacing={2}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ height: "80%" }}
                >
                  {profileData.map((ele, index) => {
                    return (
                      <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                        <ImageTab
                          profileData={ele}
                          onHandleFilter={onHandleApplyFilter}
                          onHandleChangeApproved={onHandleChangeApproved}
                          onHandleChangeRemoved={onHandleChangeRemoved}
                          role={role}
                          collection={filterParam.collection || ""}
                          traits={(() =>
                            traitsAtt[filterParam.collection] &&
                            traitsAtt[filterParam.collection]
                              .filter((elem) => {
                                return Object.keys(ele).some((ele) => {
                                  return ele === elem.property;
                                });
                              })
                              ?.map((ele) => ele.property))()}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              }
            </Grid>
            {paginationCount !== null && (
              <Grid item sx={{ ...PaginationContainerGridStyle }}>
                {paginationCount > limit ? (
                  <Pagination
                    count={Math.ceil(paginationCount / limit)}
                    sx={{ ...PaginationStyle }}
                    page={offset}
                    onChange={handleChangePage}
                  />
                ) : (
                  <div />
                )}
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <Typography sx={itemPerPageStyle}>Items per page</Typography>
                  <Select
                    value={limit || ""}
                    disableUnderline
                    variant={"standard"}
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={handleChangeLimit}
                    sx={itemSelect}
                  >
                    {pageLimitOptions.map((ele) => (
                      <MenuItem value={ele} key={"option" + ele}>
                        {ele}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ margin: "auto" }}>
          <CircularProgress
            sx={{
              margin: "auto",
              color: "white",
              display: "block",
              width: "60px",
              height: "60px",
            }}
          />
        </Box>
      )}
    </Box>
  );
}
