import React from "react";
import { Breadcrumbs, Typography, Grid, Box, Link } from "@mui/material";
import { mainContentText, selectedRemoveText, subText } from "./style";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { COLOR } from "../../../appStyles/colorPalette/color";

const CustomBreadcrumbs = ({
  breadProps,
  rootLink,
  collection,
  removedItem,
  role,
}) => {
  let active =
    Boolean(breadProps) &&
    Boolean(breadProps.length) &&
    breadProps[0].value &&
    breadProps[0].value.length === 1
      ? true
      : false;
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        p: 2,
        overflow: "hidden",
      }}
    >
      <Breadcrumbs
        separator={
          <ArrowForwardIosIcon
            fontSize="small"
            sx={{
              color: COLOR.gray2,
            }}
          />
        }
      >
        <Link
          color="inherit"
          // href="/"
          onClick={rootLink}
          sx={{ textDecoration: "none" }}
        >
          <Typography sx={{ ...mainContentText }}>
            {collection || "NASCAR"}
          </Typography>
        </Link>
        {Boolean(breadProps) &&
          Boolean(breadProps.length) &&
          breadProps[0].value &&
          breadProps[0].value.length && (
            <Link
              color="inherit"
              onClick={() => {}}
              sx={{ textDecoration: "none" }}
            >
              <Typography sx={{ ...subText }}>
                {active
                  ? `${breadProps[0].name}: ${breadProps[0].value[0]}`
                  : `Filtered Items`}
              </Typography>
            </Link>
          )}
        {Boolean(role) && Boolean(removedItem) && (
          <Typography sx={{ ...selectedRemoveText }}>
            {`Selected ${removedItem} to be removed`}
          </Typography>
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default CustomBreadcrumbs;
