import { COLOR } from "../../../appStyles/colorPalette/color";

export const drawerStyle = {
  sx: {
    backgroundColor: COLOR.rightDrawerBG,
    width: { xs: "100%", sm: "70%", md: "65%", lg: "50%" },
    pl: "25px",
    pr: "25px",
    display: "flex",
    alignItems: "center",
  },
};

export const commentStyle = {
  color: COLOR.white,
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "15px",
};

export const commentContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mt: "10px",
  borderBottom: `1px solid #626586`,
  width: "100%",
};

export const imageConfigured = {
  borderRadius: "8px",
  height: "auto",
  //   width: "35%",
  //   height: "226.99px",
  //   width: "45%",
};

export const imageContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mt: "15px",
};

export const imageContainer1 = {
  display: "flex",
  alignItems: "center",
  pb: "20px",
  pt: "10px",
  borderBottom: `1px solid rgba(217, 217, 217, 0.28);`,
};

export const idStyle = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "18px",
  color: "#6FA9FA",
};

export const approvedStyle = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "13px",
  color: COLOR.white,
  mr: "10px",
};

export const approvedSwitchStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const subContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mb: "10px",
};

export const hrStyle = {
  height: "1px",
  background: `rgba(217, 217, 217, 0.28)`,
  mt: "50px",
  mb: "25px",
};

export const headerName = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "15px",
  textTransform: "capitalize",
  color: COLOR.white,
};

export const commentTextStyle = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  color: "#A0A2B1",
};

export const selectText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  color: "#ABAEC2",
};

export const hrStyleBody = {
  background: "rgba(217, 217, 217, 0.28)",
  height: "1px",
  width: "100%",
  mt: "10px",
  mb: "10px",
};

export const commentInputCont = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  columnGap: "5px",
  marginTop: "15px",
  marginBottom: "10px",
};

export const commentInputBox = {
  display: "flex",
  padding: "0px 10px",
  justifyContent: "center",
  width: "calc(100% - 38px)",
  "& ::-webkit-scrollbar": {
    display: "none"
  }
};
