import axios from "axios";
// import { BASE_URL } from '../constants';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAuthHeader = () => {
  let user = JSON.parse(localStorage.getItem("userAuth"));
  if (user && user?.token)
    return { headers: { Authorization: `Bearer ${user?.token}` } };
  else return {};
};

const insecureAxiosInstance = axios.create({
  baseURL: BASE_URL,
});

const tokenizedAxiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  ...getAuthHeader(),
});

insecureAxiosInstance.interceptors.request.use(
  (config) => {
    // config.headers["Access-Control-Allow-Origin"] = "*";
    // config.headers["Access-Control-Allow-Headers"] = "*";
    // config.headers["content-type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

insecureAxiosInstance.interceptors.response.use(
  (response) => {
    // const result = parseBody(response);
    const result = response;
    return result;
  },
  (error) => {
    // return parsedError(error.response);
    return error.response;
  }
);

tokenizedAxiosInstance.interceptors.request.use(
  (req) => {
    return req;
  },
  (config) => {
    // config.headers["Access-Control-Allow-Origin"] = "*";
    // config.headers["Access-Control-Allow-Headers"] = "*";
    // config.headers["content-type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

tokenizedAxiosInstance.interceptors.response.use(
  (response) => {
    // const result = parseBody(response);
    const result = response;
    return result;
  },
  (error) => {
    // return parsedError(error.response);
    if (
      error?.response?.data?.message === "Token is missing" ||
      error?.response?.data?.message === "Invalid Token" ||
      error?.response?.data?.message === "unauthorized"
    ) {
      localStorage.removeItem("userAuth");
      window.location.pathname !== "/login" && (window.location.href = "/login");
    }
    return error.response;
  }
);

export const setTokenForPrivate = (token) => {
  tokenizedAxiosInstance.defaults.headers.common["Authorization"] = "";
  delete tokenizedAxiosInstance.defaults.headers.common["Authorization"];
  if (token) {
    tokenizedAxiosInstance.defaults.headers.common["Authorization"] =
      "Bearer " + token;
  }
};

export const requestMethod = {
  get: "get",
  post: "post",
};
export const api = insecureAxiosInstance;
export const privateApi = tokenizedAxiosInstance;
