import { api, privateApi, setTokenForPrivate } from "../apiConfig";
import { doApiCall } from "../apiConfig/doAPICall";

export const login = (body) => {
  return new Promise((resolve, reject) => {
    doApiCall(api, "post", "/login", body)
      .then((loginResponse) => {
        if (loginResponse && loginResponse?.data?.data?.token) {
          setTokenForPrivate(loginResponse?.data?.data?.token);
        }
        resolve(loginResponse);
      })
      .catch((loginError) => {
        reject(loginError);
      });
  });
};

export const generativeViewer = (param) => {
  return new Promise((resolve, reject) => {
    let url = `/generativeViewer`;
    privateApi
      .post(url, param)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCommentsById = (commentParam) => {
  return new Promise((resolve, reject) => {
    let url = `/getCommentById`;
    privateApi
      .post(url, commentParam)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendCommentsById = (commentParam) => {
  return new Promise((resolve, reject) => {
    let url = `/comment`;
    privateApi
      .post(url, commentParam)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const onHandleApproved = (param) => {
  return new Promise((resolve, reject) => {
    let url = `/approve`;
    privateApi
      .post(url, param)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTraitsByCollection = (param) => {
  return new Promise((resolve, reject) => {
    let url = `/getTraits`;
    privateApi
      .post(url, param)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCollections = () => {
  return new Promise((resolve, reject) => {
    let url = `/getCollections`;
    privateApi
      .get(url)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
