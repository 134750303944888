import React from "react";
import { Box, Chip, Button, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { mainBody, mainBody2 } from "./style";

const CustomChip = (props) => {

  return (
    <Box>
      {props.chipData &&
        Boolean(props.chipData) &&
        Boolean(props.chipData.length) &&
        props.chipData[0].value &&
        props.chipData.map((ele, index) =>
          ele.value.map((item, subIndex) => (
            <Chip
              key={`${index}-${subIndex}`}
              label={`${ele.name}: ${item}`}
              // onClick={() => {}}
              onDelete={() => props.handleDelete(ele, item)}
              sx={{ ...mainBody }}
              deleteIcon={
                <ClearIcon fontSize="small" style={{ color: "white" }} />
              }
            />
          ))
        )}
      {props.chipData &&
        Boolean(props.chipData) &&
        Boolean(props.chipData.length) &&
        props.chipData[0].value && (
          <Button variant="text" onClick={props.onHandleClear}>
            <Typography
              sx={{
                ...mainBody2,
              }}
            >
              {`Clear all`}
            </Typography>
          </Button>
        )}
    </Box>
  );
};

export default CustomChip;
