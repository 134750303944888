export const headerText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  color: "#6FA9FA",
};

export const bodyText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "15px",
  color: "#FFFFFF",
};

export const contentText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "12px",
  color: "rgba(255, 255, 255, 0.79)",
};
