import { React, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import "./login.css";
import SubnationLogo from "../../assets/image/SubnationLogo.png";
import { useAuth } from "../../hooks/auth";
import { login } from "../../services";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuth();

  const handleOnSubmit = async () => {
    const body = {
      email: email,
      password: password,
    };
    setLoading(true);
    login(body)
      .then((loginRes) => {
        if (loginRes.data.statusCode === 200) {
          let user = (loginRes.data && loginRes.data.data) || null;
          if (user) {
            user.collections &&
              (user.collections = JSON.parse(user.collections));
            setUser(user);
          }
        } else if (
          loginRes?.data?.statusCode == 404 ||
          loginRes?.data?.statusCode == 403
        ) {
          setErrMsg("Email/password doesn't match");
        }
        setLoading(false);
      })
      .catch((loginErr) => {
        setLoading(false);
        console.log("loginErr====", loginErr);
      });
  };

  return (
    <div className="login-page">
      <Box
        // container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#292c4e",
          padding: "30px",
          borderRadius: "20px",
          width: "35%",
        }}
      >
        <Box sx={{ mt: 1 }}>
          <Grid container>
            <Grid item sm={12} xs={12} lg={6} sx={{ mb: 5 }}>
              <img src={SubnationLogo} alt={"subnation-logo"} />
            </Grid>
          </Grid>
          <Typography
            fontFamily={"unset"}
            color={"#fff"}
            fontWeight={"500"}
            fontSize="16px"
          >
            Email
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            sx={{
              backgroundColor: "#3c3e5c",
              borderRadius: "8px",
              letterSpacing: "0.5px",
              input: { color: "#fff" },
              "& fieldset": { border: "none" },
            }}
          />
          <Typography
            fontFamily={"unset"}
            color={"#fff"}
            fontWeight={"500"}
            fontSize="16px"
          >
            Password
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            type="password"
            value={password}
            disabled={loading}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              backgroundColor: "#3c3e5c",
              borderRadius: "8px",
              letterSpacing: "0.5px",
              input: { color: "#fff" },
              "& fieldset": { border: "none" },
            }}
          />
          {errorMsg ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "10px auto",
                fontFamily: "Inter-Medium",
                color: "red",
              }}
            >
              {errorMsg}
            </Box>
          ) : (
            ""
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={loading}
              type="submit"
              // fullWidth
              variant="contained"
              onClick={() => handleOnSubmit()}
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#6fa9fa",
                borderRadius: "8px",
                color: "#ffffff",
                cursor: "pointer",
                width: "40%",
                textTransform: "none",
                "&.Mui-disabled": {
                  backgroundColor: "#6fa9fa",
                  color: "#ffffff",
                  opacity: "0.5",
                },
              }}
            >
              Log In
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
