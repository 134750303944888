import { RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import routes from "./routes";

function App() {
  return (
    <div>
      <CssBaseline />
      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
