import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useOutlet, useLocation } from "react-router-dom";
import { setTokenForPrivate } from "../apiConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser, logout] = useGetUser();

  const value = useMemo(
    () => ({
      user,
      setUser,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const useGetUser = () => {
  const [user, setUser] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("userAuth"));
    if (user) {
      setUser(user);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (user?.id && location.pathname === "/login") {
      navigate("/");
    }
  }, [user]);

  const addUser = (data) => {
    window.localStorage.setItem("userAuth", JSON.stringify(data));
    setUser(data);
  };

  const logout = () => {
    window.localStorage.removeItem("userAuth");
    setUser({});
    navigate("/login");
  };
  return [user, addUser, logout];
};

export const AuthLayout = () => {
  const outlet = useOutlet();

  return <AuthProvider>{outlet}</AuthProvider>;
};
