import { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  Button,
  ClickAwayListener,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";

import CustomAvatar from "../common/customAvatar";
import { dropdownSelectStyle } from "../../pages/GenerativeViewer/style";
import { useAuth } from "../../hooks/auth";
import Vector from "../../assets/image/Vector.png";
import DrawerHeader from "../common/DrawerHeader";
import { COLOR } from "../../appStyles/colorPalette/color";
import SubnationLogo from "../../assets/image/SubnationLogo.png";
import AppBar from "../common/AppBar";

const drawerWidth = 270;

export default function Layout() {
  const { user, logout } = useAuth();
  const [showLogout, setShowLogout] = useState(false);

  return (
    <div>
      <AppBar
        position="fixed"
        open={true}
        style={{ background: "#1D2145", boxShadow: "none" }}
      >
        <Toolbar style={{ justifyContent: "flex-end", padding: "24px 30px" }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            color={"#FFF"}
            display={"flex"}
            alignItems={"center"}
          >
            <CustomAvatar
              customStyle={{
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "30px",
                height: "50px",
                width: "50px",
                background: user?.backGroundColor,
              }}
              name={user?.name ? user?.name[0] : ""}
            />
            <Box
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              onClick={() => setShowLogout(!showLogout)}
            >
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontFamily: "Inter-Medium",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "30px",
                  color: "#FFFFFF",
                  ml: 1,
                  mr: 1,
                  textTransform: "capitalize",
                }}
              >
                {user?.name || ""}
              </Typography>
              <Box component={"img"} src={Vector} />
            </Box>
          </Typography>
        </Toolbar>
        {showLogout && (
          <ClickAwayListener onClickAway={() => setShowLogout(false)}>
            <Box
              sx={{
                ...dropdownSelectStyle,
                position: "absolute",
                top: "90px",
                right: "10px",
                width: "190px",
                borderRadius: "10px",
                paddingLeft: "0px",
              }}
            >
              <Button
                fullWidth
                variant="text"
                sx={{ height: "40px" }}
                onClick={logout}
              >
                <Typography
                  sx={{
                    fontFamily: "IBMPlexSans-Light",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "18px",
                    color: "#FFFFFF",
                    height: "40px",
                    textTransform: "capitalize",
                    alignItems: "center",
                    display: "flex",
                    lineHeight: null,
                  }}
                >
                  Logout
                </Typography>
              </Button>
            </Box>
          </ClickAwayListener>
        )}
      </AppBar>
      <Drawer
        sx={{
          background: COLOR.background,
          width: drawerWidth,
          flexShrink: 0,

          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={true}
        PaperProps={{
          sx: {
            background: COLOR.background,
          },
        }}
      >
        <DrawerHeader
          style={{
            background: COLOR.background,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component={"img"}
            src={SubnationLogo}
            sx={{ width: "194px", height: "23px", mt: "48px", mb: "82px" }}
          />
        </DrawerHeader>
        <List style={{ background: COLOR.background }}>
          {["Generative Viewer"].map((text, index) => (
            <ListItem key={index}>
              <ListItemButton
                sx={{
                  borderRadius: "8px",
                  backgroundColor: COLOR.bottonActive,
                  display: "flex",
                  alignItems: "center",
                  width: "210px",
                  height: "48px",
                  "&:hover": {
                    backgroundColor: COLOR.bottonActive,
                  },
                }}
              >
                <ListItemText
                  primary={text}
                  sx={{
                    "& span": {
                      color: "#FFF",
                      fontFamily: "IBM Plex Sans",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "32px",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        sx={{
          position: "relative",
          left: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Outlet />
      </Box>
    </div>
  );
}
