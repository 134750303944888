import { Typography, Box, Modal } from "@mui/material";
import React, { useState } from "react";
import CustomGenDetailsViewer from "../common/customGenDetailsViewer";
import "./GenerativeViewer.css";
import {
  approvedText,
  countText,
  idText,
  subContainer,
  removeText,
} from "./style";
// import nftImage from '../assets/image/nftImage.png'
import CustomizedCheckbox from "../common/customizedCheckbox";
import CommentDrawer from "./CommentDrawer";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

function ImageTab({
  profileData,
  onHandleChangeApproved,
  onHandleChangeRemoved,
  role,
  onHandleFilter,
  collection,
  traits = [],
}) {
  let {
    signedImageUrl,
    id,
    itemName,
    isApproved,
    commentsCount,
    signedThumbnailUrl,
    signedVideoUrl,
    isRemoved,
  } = profileData;
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleApprove = async () => {
    await onHandleChangeApproved(
      isApproved === 1
        ? {
            disApprovedItemIds: [id],
          }
        : {
            ApprovedItemIds: [id],
          }
    );
  };

  const handleRemoved = async () => {
    await onHandleChangeRemoved(
      isRemoved === 1
        ? {
            includedItemIds: [id],
          }
        : {
            removedItemIds: [id],
          }
    );
  };

  const onClickImage = () => {
    setOpen(true);
  };

  const handleModalOpen = () => {
    setModalOpen((prevState) => !prevState);
  };

  const handlePlayClick = (event) => {
    event.stopPropagation();
    handleModalOpen();
  };

  return (
    <div className="image-configured">
      <CommentDrawer
        open={open}
        close={() => setOpen(false)}
        profileData={profileData}
        checkData={isApproved === 1 ? true : false}
        checkHandle={handleApprove}
        onClickProperties={() => {}}
        collection={collection}
        traits={traits}
      />
      <Box
        sx={{
          display: "flex",
          padding: `5px 5px`,
          borderRadius: `0px 8px`,
          mb: "15px",
          alignSelf: "end",
          alignItems: "center",
        }}
      >
        <Typography sx={{ ...approvedText }}>
          {isApproved === 1 ? `Approved` : `Approve`}
        </Typography>
        <CustomizedCheckbox
          disabled={role && isRemoved === 1 ? true : false}
          checked={isApproved === 1 ? true : false}
          onChange={handleApprove}
        />
        {role && (
          <>
            <Typography sx={{ ...removeText }}>
              {isRemoved === 1 ? `Removed` : `Remove`}
            </Typography>
            <CustomizedCheckbox
              disabled={isApproved === 1 ? true : false}
              checked={isRemoved === 1 ? true : false}
              onChange={handleRemoved}
            />
          </>
        )}
      </Box>
      <div style={{ width: "100%" }}>
        <Box sx={{ position: "relative" }}>
          <Box
            alt={collection + " #" + id}
            component={"img"}
            src={signedImageUrl ?? signedThumbnailUrl}
            sx={{ cursor: "pointer" }}
            // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            //loading="lazy"
            onClick={onClickImage}
          />
          {signedThumbnailUrl && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                cursor: "pointer",
                "& > svg": {
                  width: "48px",
                  height: "48px",
                  color: "white",
                  background:
                    "radial-gradient(rgb(84 83 83 / 60%) 40%, transparent 60%)",
                  borderRadius: "25px",
                },
              }}
              onClick={handlePlayClick}
            >
              <PlayCircleOutlineIcon />
            </Box>
          )}
        </Box>
      </div>

      <div className="image-details">
        <Box sx={{ ...subContainer }}>
          <Typography sx={{ ...idText }}>#{itemName}</Typography>
          <Typography sx={{ ...countText }} onClick={onClickImage}>
            {Boolean(commentsCount)
              ? commentsCount > 1
                ? `${commentsCount} Comments`
                : `${commentsCount} Comment`
              : `0 Comments`}
          </Typography>
        </Box>
        <div className="box-details">
          <div className="button-details">
            {traits.map((trait) => (
              <CustomGenDetailsViewer
                key={trait}
                label={trait}
                value={
                  profileData[trait]
                    ? profileData[trait][trait] || "None"
                    : "None"
                }
                onclickFilter={() =>
                  onHandleFilter(
                    trait,
                    profileData[trait]
                      ? profileData[trait][trait] || "None"
                      : "None"
                  )
                }
                content={
                  profileData[trait] ? profileData[trait]["percentage"] || 0 : 0
                }
              />
            ))}
          </div>
        </div>
      </div>
      <Modal open={modalOpen} onClose={handleModalOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: 0,
          }}
        >
          <video
            width="480"
            height="480"
            loop
            autoPlay
            controls
            style={{ outline: 0, borderRadius: "12px" }}
          >
            <source src={signedVideoUrl} type="video/mp4" />
          </video>
        </Box>
      </Modal>
    </div>
  );
}

export default ImageTab;
