import { COLOR } from "../../../appStyles/colorPalette/color";

export const headerText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14.5px",
  color: COLOR.blue,
  pb: "6px",
};

export const subHeaderContainer = {
  mt: "5px",
  mb: "25px",
};

export const subHeaderText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "15px",
  color: "#A0A2B1",
  pb: "6px",
  cursor: "pointer",
};

export const subActiveHeaderText = {
  fontFamily: "IBMPlexSans-Light",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "20px",
  color: COLOR.white,
  pb: "6px",
  cursor: "pointer",
};
