import { Typography, Box } from "@mui/material";
import React from "react";
import { bodyText, contentText, headerText } from "./style";

const CustomGenDetailsViewer = ({
  label,
  value,
  onclickFilter = () => {},
  content,
}) => {
  return (
    <button className="each-button" onClick={onclickFilter}>
      <Typography
        className="each-button-label"
        fontFamily={"IBMPlexSans-Light"}
        sx={{ ...headerText }}
      >
        {label}
      </Typography>
      <Typography
        className="each-button-label-desc"
        fontFamily={"IBMPlexSans-Medium"}
        sx={{ ...bodyText }}
      >
        {value}
      </Typography>
      <Typography
        className="each-button-label-desc"
        fontFamily={"IBMPlexSans-Medium"}
        sx={{ ...contentText }}
      >
        {content === "None" ? "None" : `${content}% have this trait`}
      </Typography>
    </button>
  );
};

export default CustomGenDetailsViewer;
